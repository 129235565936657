:root {
  --error-color: #D94235;
  --color: #B2A169;
  --bg-color: hsl(140, 10%, 90%);
  --primary-color: #B2A169;
  --secondary-color: #8B8F92;
  --even-color: #fcfaf0;

  --font-size: 13pt;
  --font-display: "Sofia Pro Light";
  --font-default: "Sofia Pro Light";
}


body {
  margin: 0;
  background-color: #f5f5f5;
  color: var(--color);
  font-family: var(--font-default);
  font-size: var(--font-size);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Sofia Pro Light';
  src: local('Sofia-Pro-Light-Az'), url(./fonts/Sofia-Pro/Sofia-Pro-Light-Az.otf) format('opentype');
}

@font-face {
  font-family: 'Sofia Pro Light';
  font-weight: bold;
  src: local('Sofia-Pro-Bold-Az'), url(./fonts/Sofia-Pro/Sofia-Pro-Bold-Az.otf) format('opentype');
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-display);
}

.font-size-default {
  font-size: var(--font-size);
}

.font-size-10 {
  font-size: 10pt !important;
}

.font-size-11 {
  font-size: 11pt !important;
}

.font-size-12 {
  font-size: 12pt !important;
}

.font-size-13 {
  font-size: 13pt !important;
}

.font-size-14 {
  font-size: 14pt;
}

.font-size-15 {
  font-size: 15pt;
}

.font-size-16 {
  font-size: 16pt;
}

.font-size-17 {
  font-size: 17pt;
}

.font-size-18 {
  font-size: 18pt;
}

.font-size-20 {
  font-size: 20pt;
}

.font-size-22 {
  font-size: 22pt;
}

.text-justify {
  text-align: justify;
}

.secondary-color{
  color: var(--secondary-color) !important;
}


.n-bg-gray{
  background-color: #F5F5F5;
  border: 1px solid;
  border-right: none !important;
}

.n-bg-default{
  background-color: var(--primary-color);
  border: 1px solid;
}

.padding-input-small{
  padding: 0.45rem 0.4rem 0.45rem 0.4rem !important;
}

.mt-n1{
  padding-top: -0.4rem !important;
}

.border-color-gray{
  border-color: var(--primary-color);
}

.border-color {
  border-color: var(--primary-color) !important;
}

.bg-color {
  background-color: var(--primary-color) !important;
}

.even{
  background-color: var(--even-color);
}

.icon-eye{
  height:21px;
  width: 21px;
}
.border-input-append{
  border-top-right-radius: .2rem !important;
  border-bottom-right-radius: .2rem !important;
}
.border-input-append2 {
  border-top-right-radius: 0.4rem!important;
  border-bottom-right-radius: 0.4rem!important;
}
.cursor-pointer {
  cursor: pointer;
}

.invalid-border-color, .form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--error-color) !important;
}

.invalid-feedback {
  color: var(--error-color) !important;
}

.background-image-none{
  background-image: none !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.icon-padding-error {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.title {
  font-size:  var(--font-size);
  font-weight: 500;
  color: var(--primary-color) !important;
}

input:-webkit-autofill, textarea:-webkit-autofill, pre:-webkit-autofill{
  box-shadow: 0 0 0 30px white inset !important;
  border-color: var(--primary-color) !important;
  -webkit-text-fill-color: var(--primary-color) !important;
 }

input[type="date"] {
  display: block;
  text-align: left;
}

 pre {
  border: 1px solid var(--primary-color)!important;
  border-radius: 0px !important;
  outline: none;
  box-shadow: none;

 }

 pre:focus, pre:active {   
  border: 1px solid var(--primary-color)!important;
  color: var(--primary-color) !important;
  border-radius: 0px !important;
  outline: none;
  box-shadow: none;
}
pre::placeholder {
  color: var(--primary-color)!important;
  opacity: 1;
  border-radius: 0px !important;
  outline: none;
  box-shadow: none;
}
pre:-ms-input-placeholder, pre::-ms-input-placeholder {
  color: var(--primary-color)!important;
  border-radius: 0px !important;
  outline: none;
  box-shadow: none;
}

 input[type="date"]::-webkit-calendar-picker-indicator{
  background-image: url("/src/icons/Icon ionic-ios-calendar.svg");
  background-repeat: no-repeat;
  background-position: 100% center;
  cursor: pointer;
  height: 13pt;
 }



 form, input, label, p, select, textarea {
  color: var(--color) !important;
}

.primary-text-color {
  color: var(--color) !important;
}

.tooltip-inner {
  --cui-tooltip-border-radius: 0px !important;
  --cui-tooltip-font-size: 10pt!important;
  --cui-tooltip-bg: var(--primary-color) !important;
  --cui-tooltip-max-width: 210px!important;
}

.tooltip .tooltip-arrow {
  --cui-tooltip-bg: var(--primary-color) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

#sidebar-wrapper .list-group {
  width: 14rem;
  height: 73vh!important;
  overflow-y: auto;
}
.list-group-flush {
  border-radius: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
#page-content-wrapper {
  min-width: 100vw;
  margin: 0 auto;
  background-color: #f5f5f5;
}


#sidebar-wrapper {
  min-height: 100vh;
  width: 14rem;
  transition: margin 0.25s ease-out;
  background-color: var(--primary-color);
}

#sidebar-wrapper2{
  min-height: 200vh;
  width: 5rem;
  transition: margin 0.25s ease-out;
  background-color: var(--primary-color);
}

#sidebar-wrapper .sidebar-heading, #sidebar-wrapper2 .sidebar-heading {
  color: white;
  font-size: 15px;
  padding: 2rem 0.875rem 0 0.875rem;
}

.navbar{
  padding-bottom: 0 !important;
}

.navbar-icon-fullSize{    
  height: 35px;
  width: 35px;
  margin-right: 60px;
  cursor: pointer;
}

.dropdown-menu-nav{
  margin-right: 30px !important;
  position: relative !important; 
  transform: none !important;
  margin-bottom: 5px !important;
  margin-top: 0 !important;
}

.col-lg-1-5-50 {
  flex: 0 0 50%;
  width: 50%;
}

@media (min-width: 992px) {

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .sidebar-show{
    margin-left: 0 !important;
  }

  .col-lg-0-5 {
    flex: 0 0 auto;
    width: 4.166666665%;
  }  

  .col-lg-1-5-50 {
    flex: 0 0 auto;
    width: 12.5%;
  }

  .border-lg-col{
    border-right: 1px solid
  }

}

.primary-color{
  color: var(--primary-color) !important;
}

@media (max-width: 991px){
  #sidebar-wrapper {
    display: none !important;
  }

  #sidebar-wrapper2 {
    display: none !important;
  }

  .navbar-icon-fullSize{
    display: none !important;
  }
}

.modal-content {
  border: none !important;
  border-radius: 0px !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;   
  height: 6px;  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:  var(--primary-color); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); 
}
.bg-green-search {
  background-color: #91d1b1!important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.border-colRightLogin{
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.border-colLeftLogin{
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@media (min-width: 768px) {
  .border-colLeftLogin{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .border-colRightLogin{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}

.dropdown-item:active,  .dropdown-item:hover, .dropdown-item:focus{
  text-decoration: none;
  color: white !important;
  background-color: #BEB083 !important;
}

select.decorated, option:hover {
  box-shadow: 0 0 10px 100px red inset;
}

.text-underline{
  text-decoration: underline;
}

.badge-color{
  background-color: #E0D9C3;
  font-weight: normal !important;
  color: var(--color)!important;
}

.badge-border{
  border: 1px solid #E0D9C3;
  font-weight: normal !important;
  color: var(--color)!important;
}

.w-badge{
  width: 60px;
}

.badge-border2 {
  font-weight: normal !important;
  color: var(--color)!important;
  border: 1px solid var(--primary-color) !important;
}

.icon-border {
  border-radius: 0px;
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid var(--color);
}  

.admin-container{
  max-width: 950px;
}
.user-container{
  max-width: 450px;
}

.mtn-1 {
  margin-top: -0.25rem!important;
}
.box, .box2, .box3, .box4, .box5 {
  height: 2.5rem;
  width: 2.5rem;
  background: white;
  overflow: hidden;
}

.box.big{
  height: 100px !important;
  width: 100% !important;
  min-height: 100px!important;
  max-height: 100px!important;
}

.box2.big2 {
  height: 160px !important;
  min-height: 160px!important;
  max-height: 160px!important;
  text-align: center;
  width: 100% !important;
}

.box3.big3, .box5.big5 {
  height: 120px !important;
  min-height: 120px!important;
  max-height: 120px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}



.box4.big4 {
  height: 160px !important;
  min-height: 160px!important;
  max-height: 160px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

.box2 img {
  width: 100%;
  height: 160px!important;
  min-height: 160px!important;
  max-height: 160px!important;
  object-fit: contain;
  object-position: center center;
}

.box4 img {
  width: 100%;
  height: 100px!important;
  min-height: 100px!important;
  max-height: 100px!important;
  object-fit: contain;
  object-position: center center;
}

.box5 img {
  width: 100%;
  height: 80px!important;
  min-height: 80px!important;
  max-height: 80px!important;
  object-fit: contain;
  object-position: center center;
}

.box3 img {
  width: 100%;
  height: 120px!important;
  min-height: 120px!important;
  max-height: 120px!important;
  object-fit: contain;
  object-position: center center;
}

.box img{
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  object-fit: contain;
  object-position: center center;
}

.col-0-5-lg {
  flex: 0 0 100%;
  width: 100%;
}

.col-1-5ths {
  flex: 0 0 100%;
  width: 100%;
}

.col-1-5ths-50 {
  flex: 0 0 50%;
  width: 50%;
}

.col-xl-2-art, .col-xl-3-art, .col-xl-1-art{
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 1200px) {
  .border-col{
    border-right: 1px solid
  }
  .col-1-5ths, .col-1-5ths-50 {
    flex: 0 0 12.5%;
  }
  .col-0-5-lg {
    flex: 0 0 3%;
  }

  .col-xl-3-art{
    flex: 0 0 auto;
    width: 28.333333334%;
  }

  .col-xl-2-art{
    flex: 0 0 auto;
    width: 20.000000004%;
  }

  .col-xl-1-art{
    flex: 0 0 auto;
    width: 11.666666664%;
  }
}



.default-nav svg {
  fill: white
}

.image-container img{
  width: 100%;
  object-fit: contain;
  object-position: center center;
}

.h-400px{
  height: 400px;
}

.h-200px{
  height: 250px;
}

.noPic{
    align-items: center;
    display: flex;
    border: 1.4px solid #F7F5EF !important;
    border-bottom: none !important;
    justify-content: center;
}

.default-nav {
  background: var(--primary-color) !important;
  border-radius: 0px!important;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  background: var(--primary-color) !important;
}

.opacity-unset{
  opacity: unset;
}

.n-bg-default {
  background-color: var(--primary-color) !important;
}

.form-check-input {
  cursor: pointer!important;
  border-radius: 0rem!important;
  border-color: var(--primary-color)!important;
  width: 0.95em!important;
  height: 0.95em!important;
  box-shadow: none !important;
  margin: 0.4em 0 0 0;
  border: 1px solid var(--primary-color)!important;
  accent-color: var(--primary-color)!important;
}

.form-check-input:checked {
  background-color: var(--primary-color)!important;
  border-color: var(--primary-color)!important;
  box-shadow: none!important;
  accent-color: var(--primary-color)!important;
}

.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logoContent {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.gray-color-text{
  color:#707070;
}

.dropdown-p{
  background: none !important;
  border: none !important;
  color: var(--color) !important;
  font-size: 12pt !important;
}

.logoutIcon {
  width: 15px;
  height: 15px;
  background: url("/src/icons/Icon log out - dorado.svg") no-repeat;
  background-size: 15px 15px;
}

.dropdown-item:hover  .logoutIcon{
  width: 15px!important;
  height: 15px!important;
  background: url("/src/icons/Icon log out - blanco.svg") no-repeat !important;
  background-size: 15px 15px !important;
}

.icon-language{
  width: 25px;
  height: 25px;
  border-radius: 0px;
  font-size: 11pt;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
  cursor: pointer;
}

.language-selected, .language-selected:active, .language-selected:hover{
  border: 1px solid var(--color) !important;
  background-color: var(--color)!important;
  color: white!important;
}

.language-not-registered, .language-not-registered:active, .language-not-registered:hover{
  border: 1px solid #DFD8C0!important;
  color: #DFD8C0!important;
  background-color: transparent!important;
}

.language-registered, .language-registered:active, .language-registered:hover{
  border: 1px solid var(--color)!important;
  color: var(--color)!important;
  background-color: transparent!important;
}

@media (max-width: 768px) {
  .logoContent {
    position: initial!important;
    width: 100%!important;
    padding: 10px 20px!important;
    justify-content: end !important;
  }
}

@media (max-width: 1403.5px) {
  .logoContent2 {
    position: initial;
    width: 100%;
    padding: 30px 20px 0px 20px;
    justify-content: end;
  }
}

@media (min-width: 1404px) {
  .logoContent2 {
    position: absolute !important;
    right: 30px!important;
    bottom: 30px!important;
  }
}